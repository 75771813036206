import React from "react";
import Page from "../Page";
import { importMDX } from "mdx.macro";
import { language } from "../locales/translate";

const ContentEN = importMDX.sync("../cms/details.en.md");
const ContentKO = importMDX.sync("../cms/details.ko.md");

function Details() {
  return <Page>{language === "ko" ? <ContentKO /> : <ContentEN />}</Page>;
}

export default Details;
