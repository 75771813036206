import React from 'react';
import styled, { keyframes } from 'styled-components';
import { config } from 'react-spring';
import { Parallax, ParallaxLayer } from 'react-spring/addons';
import t from '../locales/translate';
import { KOREAN, KOREAN_HEADING } from '../styles';
import useOrientation from '../hooks/useOrientation';
import useScrolled from '../hooks/useScrolled';
import Scene1 from './scenes/Scene1/Scene1';
import Scene2 from './scenes/Scene2/Scene2';
import Scene3 from './scenes/Scene3/Scene3';
import Scene4 from './scenes/Scene4/Scene4';
import Scene5 from './scenes/Scene5/Scene5';
import Scene6 from './scenes/Scene6/Scene6';
import nokuLandscape from './scenes/Scene7/noku_2000.jpg';
import nokuPortrait from './scenes/Scene7/noku_mobile.jpg';
import Scene8 from './scenes/Scene8/Scene8';
import Scene9 from './scenes/Scene9/Scene9';
import Scene10 from './scenes/Scene10/Scene10';
import Scene11 from './scenes/Scene11/Scene11';
import { ReactComponent as Arrow } from './scenes/Scene1/arrow.svg';

const SyledParallax = styled(Parallax)`
  line-height: 0;
  background: white;
`;

const Scene = ({ offset, className, children }) => (
  <div className={className}>{children}</div>
);

const SceneBase = styled(Scene)`
  position: relative;
  display: flex;
  margin: 0 15vw;
  top: ${props => props.offset * 100}vh;

  @media (orientation: portrait) {
    margin: 0 5vw;
  }
`;

const bounce = keyframes`
  from { transform: translate(0, -50%); }
  to { transform: translate(0, 0); }
`;

const StyledArrow = styled(Arrow)`
  position: fixed;
  z-index: 1;
  top: 95vh;
  left: 50%;
  width: 50px;
  margin-left: -25px;
  height: auto;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  animation: ${bounce} 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);

  @media (orientation: portrait) {
    opacity: 0.8;
    top: calc(100vh - 100px);
  }

  path {
    fill: white;
  }

  &.is-scrolled {
    opacity: 0;
  }
`;

const Scene1Container = styled(SceneBase)`
  height: 110vh;
  align-items: flex-end;
  margin-right: auto;
  max-width: 70vw;

  @media (orientation: portrait) {
    height: 100vh;
    max-width: 90vw;
  }
`;

const Scene2Container = styled(SceneBase)`
  max-width: 30vw;
  margin-left: auto;
  margin-right: 5vw;

  @media (orientation: portrait) {
    max-width: 30vw;
    margin-left: auto;
  }
`;

const Scene3Container = styled(SceneBase)`
  justify-content: flex-start;
  margin-left: 20vw;
  max-width: 50vw;

  @media (orientation: portrait) {
    margin-left: 20vw;
    max-width: none;
  }
`;

const Scene4Container = styled(SceneBase)`
  justify-content: flex-end;

  @media (orientation: portrait) {
    margin-right: 20vw;
  }
`;

const Scene5Container = styled(SceneBase)`
  justify-content: flex-start;
`;

const Scene6Container = styled(SceneBase)`
  justify-content: center;
  max-width: 50vw;
  margin: 0 auto;

  @media (orientation: portrait) {
    max-width: none;
    margin: 0;
  }
`;

const Scene7Container = styled(SceneBase)`
  justify-content: center;
  margin: 0 auto;

  @media (orientation: portrait) {
    margin: 0;
  }
`;

const NokuLink = styled.a`
  display: block;
`;

const Noku = styled.img`
  width: 100%;
  max-height: 110vh;
  object-fit: cover;
`;

const BaseNokuText = styled.a`
  text-align: center;
  font-weight: bold;
  color: white;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  ${KOREAN_HEADING}
`;

const Here = styled(BaseNokuText)`
  font-size: 14vw;
  letter-spacing: -0.5vw;

  @media (orientation: portrait) {
    font-size: 25vw;
  }
`;

const Location = styled(BaseNokuText)`
  font-size: 28px;

  span {
    font-size: 0.5em;

    ${KOREAN`
      font-size: 0.7em;
    `}
  }

  @media (orientation: portrait) {
    font-size: 16px;
  }
`;

const Scene8Container = styled(SceneBase)`
  justify-content: center;
  margin-left: 2vw;
  max-width: 70vw;

  @media (orientation: portrait) {
    max-width: 85vw;
  }
`;

const Scene9Container = styled(SceneBase)`
  justify-content: flex-end;
  margin: 0 5vw 0 auto;
  max-width: 45vw;

  @media (orientation: portrait) {
    max-width: 80vw;
  }
`;

const Scene10Container = styled(SceneBase)`
  justify-content: flex-start;
  margin-left: 10vw;
  max-width: 60vw;

  @media (orientation: portrait) {
    max-width: 90vw;
  }
`;

const Scene11Container = styled(SceneBase)`
  justify-content: center;
  margin: 0 auto;
  max-width: 35vw;

  @media (orientation: portrait) {
    margin: 0 auto;
    max-width: 45vw;
  }
`;

const nokuLink = {
  href: 'https://goo.gl/maps/cvnfBJyK9uA2',
  target: '_blank',
  rel: 'noopener noreferrer',
};

function Home() {
  const [, isMobile] = useOrientation();
  const isScrolled = useScrolled();

  return (
    <SyledParallax pages={isMobile ? 5.6 : 6} config={config.default}>
      <StyledArrow className={isScrolled ? 'is-scrolled' : ''} />
      <ParallaxLayer speed={0.2}>
        <Scene1Container offset={0}>
          <Scene1 width={600} />
        </Scene1Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.4} style={{ zIndex: 1 }}>
        <Scene2Container offset={1.1}>
          <Scene2 width={280} />
        </Scene2Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.6}>
        <Scene3Container offset={isMobile ? 1.7 : 1.8}>
          <Scene3 width={500} />
        </Scene3Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.3}>
        <Scene4Container offset={isMobile ? 1.95 : 2}>
          <Scene4 width={300} />
        </Scene4Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.6}>
        <Scene5Container offset={isMobile ? 3.1 : 3.2}>
          <Scene5 width={400} />
        </Scene5Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.4}>
        <Scene6Container offset={isMobile ? 3.4 : 3.6}>
          <Scene6 width={600} />
        </Scene6Container>
      </ParallaxLayer>

      <ParallaxLayer factor={0.2} speed={0.6} style={{ zIndex: 1 }}>
        <Scene7Container offset={isMobile ? 4.4 : 4.8}>
          <Here {...nokuLink}>{t('home.here')}</Here>
        </Scene7Container>
      </ParallaxLayer>
      <ParallaxLayer speed={0.4}>
        <Scene7Container offset={isMobile ? 3.9 : 4.3}>
          <NokuLink {...nokuLink}>
            <Noku src={isMobile ? nokuPortrait : nokuLandscape} alt="" />
          </NokuLink>
        </Scene7Container>
      </ParallaxLayer>
      <ParallaxLayer factor={0.2} speed={0.2} style={{ zIndex: 1 }}>
        <Scene7Container offset={isMobile ? 3.8 : 4.4}>
          <Location {...nokuLink}>
            {t('home.location.name')}
            <br />
            {t('home.location.place')}
            <br />
            <span>{t('home.location.cta')}</span>
          </Location>
        </Scene7Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.6}>
        <Scene8Container offset={isMobile ? 5.2 : 6.2}>
          <Scene8 width={500} />
        </Scene8Container>
      </ParallaxLayer>

      <ParallaxLayer speed={isMobile ? 0.7 : 0.4}>
        <Scene9Container offset={isMobile ? 5.9 : 5.9}>
          <Scene9 width={400} />
        </Scene9Container>
      </ParallaxLayer>

      <ParallaxLayer speed={isMobile ? 0.5 : 0.6}>
        <Scene10Container offset={isMobile ? 5.65 : 7}>
          <Scene10 width={600} />
        </Scene10Container>
      </ParallaxLayer>

      <ParallaxLayer speed={0.4}>
        <Scene11Container offset={isMobile ? 6 : 7.2}>
          <Scene11 width={400} />
        </Scene11Container>
      </ParallaxLayer>
    </SyledParallax>
  );
}

export default Home;
