import React, { useRef } from 'react';
import styled from 'styled-components';
import { Spring, animated } from 'react-spring';
import t, { language } from '../../../locales/translate';
import useVisibility from '../../../hooks/useVisibility';
import image from './j.jpg';
import bubble_EN from './bubble.en.svg';
import bubble_KO from './bubble.ko.svg';
import Line from './line';

const bubble = language === 'ko' ? bubble_KO : bubble_EN;

const Container = styled.div`
  width: ${props => props.sceneWidth}px;
  max-width: 70%;
  position: relative;
`;

const Bubble = styled(animated.img)`
  position: absolute;
  top: -32%;
  left: -35%;
  width: 80%;
  z-index: 2;
  transform-origin: bottom right;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
  left: -0.5%;
`;

const StyledLine = styled(Line)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const intersectionOptions = { threshold: [0.5, 0.9] };

function Scene8({ width }) {
  const ref = useRef(null);
  const { isIntersecting, intersectionRatio } = useVisibility(
    ref,
    intersectionOptions,
  );

  return (
    <Container sceneWidth={width}>
      <Image src={image} alt="" ref={ref} />
      <Spring from={{ scale: 0 }} to={{ scale: isIntersecting ? 1 : 0 }}>
        {({ scale }) => (
          <Bubble
            src={bubble}
            alt={t('home.join')}
            style={{
              transform: `rotate(${(1 - scale) * -45}deg) scale(${scale})`,
            }}
          />
        )}
      </Spring>
      <StyledLine isActive={intersectionRatio >= 0.9} />
    </Container>
  );
}

export default Scene8;
