import { useEffect, useState } from 'react';
const storage = window.localStorage;

function useSimpleStorage(key, defaulValue = '') {
  const storedValue = storage.getItem(key);
  const [value, setValue] = useState(
    storedValue != null ? storedValue : defaulValue,
  );

  useEffect(() => {
    storage.setItem(key, value);
  }, [key, value]);

  return [value || defaulValue, setValue];
}

export default useSimpleStorage;
