import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import t from '../locales/translate';
import useScrolled from '../hooks/useScrolled';
import { ReactComponent as Logo } from './AJ.svg';
import { COLOUR_A, COLOUR_J } from '../styles';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (props.isCompressed ? 50 : 160)}px;
  color: ${props => (props.isCompressed ? '#fff' : '#2B3252')};
  transition-property: background, height, color;
  z-index: 1;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    transform: scaleY(${props => (props.isCompressed ? 1 : 1.4)});
    background: linear-gradient(to right, ${COLOUR_A}, ${COLOUR_J});
    opacity: ${props => (props.isCompressed ? 1 : 0)};
    transition-property: transform, opacity;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #logo {
    display: block;
    height: ${props => (props.isCompressed ? 30 : 100)}px;
    width: auto;
    transition-property: background, height;
  }

  #logo path {
    ${props => (props.isCompressed ? 'fill: currentColor;' : '')}
    transition-property: fill;
  }

  a {
    cursor: pointer;
    pointer-events: auto;
    z-index: 1;
    font-size: 14px;
    font-weight: bold;
    color: currentColor;
    text-decoration: none;
    text-transform: uppercase;
    transition-property: border, transform;
  }

  .link-story,
  .link-contact {
    position: absolute;
    top: 50%;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom-color: currentColor;
    }
  }

  .link-story {
    right: 50%;
    transform: ${props =>
      props.isCompressed
        ? 'translate(-40px, -50%)'
        : 'translate(100%, 70px) translateX(-103px)'};
  }

  .link-contact {
    left: 50%;
    transform: ${props =>
      props.isCompressed
        ? 'translate(40px, -50%)'
        : 'translate(-100%, 70px) translateX(103px)'};
  }

  @media (orientation: portrait) {
    &:before {
      border-width: ${props => (props.isCompressed ? '50px 10px 10px' : '0px')};
    }

    .link-story {
      right: auto;
      left: 0;
      transform: ${props =>
        props.isCompressed
          ? 'translate(20px, -50%)'
          : 'translate(50vw, 70px) translateX(-103px)'};
    }

    .link-contact {
      left: auto;
      right: 0;
      transform: ${props =>
        props.isCompressed
          ? 'translate(-20px, -50%)'
          : 'translate(-50vw, 70px) translateX(103px)'};
    }
  }

  &,
  &:before,
  a,
  #logo,
  #logo path {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.3, 1);
  }
`;

function Header({ location }) {
  const isScrolled = useScrolled();
  const isStory = location.pathname === '/story';

  return (
    <Nav isCompressed={isScrolled || !isStory}>
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <Link className="link-story" to="/story">
        {t('navigation.story')}
      </Link>
      <Link className="link-contact" to="/contact">
        {t('navigation.contact')}
      </Link>
    </Nav>
  );
}

export default withRouter(Header);
