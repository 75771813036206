import React from 'react';

export default {
  navigation: {
    rsvp: 'RSVP',
    story: 'Story',
    contact: 'Contact',
  },
  login: {
    password: 'Password',
  },
  home: {
    married: `We're getting married!`,
    omg: 'OMG!',
    wait: `Wait... arent'we already married?`,
    but: `Yes but...`,
    properly: `This time we're doing it properly!`,
    where: 'Where?',
    here: 'HERE!',
    location: {
      name: 'Noku Beach House',
      place: 'Seminyak - Bali',
      cta: 'Click here for location',
    },
    when: 'When?',
    date: 'August 31, 2019',
    join: `So come join us for a sunset ceremony and reception!`,
    click: 'Click here',
    rsvp: 'to RSVP',
    day: 'Wedding day details coming soon.',
    see: 'See you there!',
  },
  rsvp: {
    header: 'RSVP',
    intro:
      'Your reply is kindly requested ASAP. Please complete the form only for guests named on invites.',
    you: 'You',
    partner: 'Your partner',
    name: 'Name',
    email: 'Email',
    attending: 'Do you?',
    yes: 'I do',
    no: `I don't`,
    diet: 'Dietary requirements?',
    none: 'None',
    gf: 'GF',
    vegetarian: 'Vegetarian',
    vegan: 'Vegan',
    message: 'Any questions?',
    submit: 'Submit',
    sorry: `Sorry we won't see you there but thanks for letting us know!`,
    thanks: (
      <>
        <p>Thanks! See you there!</p>
        <p>Event details to follow soon.</p>
      </>
    ),
    error: 'There was a problem submitting, please try again.',
  },
  contact: {
    header: 'Contact',
  },
};
