import React, { useCallback } from 'react';
import styled from 'styled-components';
import t from './locales/translate';
import { ReactComponent as Logo } from './Header/AJ.svg';
import { COLOUR_A, COLOUR_J, KOREAN } from './styles';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled(Logo)`
  width: 200px;
  margin-top: -100px;
`;

const StyledLabel = styled.label`
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: ${COLOUR_J};
  ${KOREAN`
    font-size: 14px;
  `}
`;

const StyledInput = styled.input`
  margin-top: 10px;
  background: white;
  padding: 5px;
  border: 1px solid transparent;
  width: 200px;
  font-size: 20px;
  transition: border-color 0.2s;
  color: ${COLOUR_A};

  &:focus {
    border-color: currentColor;
    outline: none;
  }
`;

function LoginForm({ onLogin, password }) {
  const onChange = useCallback(({ target: { value } }) => {
    if (value.toLowerCase() === password) {
      onLogin(true);
    }
  }, [onLogin, password]);

  return (
    <Container>
      <StyledLogo />
      <StyledLabel>{t('login.password')}</StyledLabel>
      <StyledInput type="password" onChange={onChange} autocomplete="off" />
    </Container>
  );
}

export default LoginForm;
