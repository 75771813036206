import React, { useRef } from 'react';
import styled from 'styled-components';
import { Spring, animated, config } from 'react-spring';
import t, { language } from '../../../locales/translate';
import useVisibility from '../../../hooks/useVisibility';
import image from './a.jpg';
import bubble_EN from './bubble.en.svg';
import bubble_KO from './bubble.ko.svg';
import Line from './line';

const bubble = language === 'ko' ? bubble_KO : bubble_EN;

const Container = styled.div`
  width: ${props => props.sceneWidth}px;
  position: relative;
`;

const Bubble = styled(animated.img)`
  position: absolute;
  top: 0;
  left: -60%;
  width: 90%;
  z-index: 2;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
`;

const StyledLine = styled(Line)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const intersectionOptions = { threshold: 0.7 };

function Scene2({ width }) {
  const ref = useRef(null);
  const { isIntersecting } = useVisibility(ref, intersectionOptions);

  return (
    <Container sceneWidth={width}>
      <Image src={image} alt="" ref={ref} />
      <Spring
        from={{ scale: 0 }}
        to={{ scale: isIntersecting ? 1 : 0 }}
        config={isIntersecting ? config.wobbly : undefined}
      >
        {({ scale }) => (
          <Bubble
            src={bubble}
            alt={t('home.omg')}
            style={{ transform: `scale(${scale})` }}
          />
        )}
      </Spring>
      <StyledLine isActive={isIntersecting} />
    </Container>
  );
}

export default Scene2;
