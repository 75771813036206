import React from 'react';
import styled from 'styled-components';
import t from '../locales/translate';
import Page from '../Page';
import { language } from '../locales/translate';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as MobileIcon } from './mobile.svg';
import { ReactComponent as WhatsappIcon } from './whatsapp.svg';
import { ReactComponent as KakaoIcon } from './kakaotalk.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 2em;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 1em;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContactMethod = ({ icon, children, lang }) =>
  (language === lang || !lang) && (
    <StyledListItem>
      {icon}
      {children}
    </StyledListItem>
  );

function Contact() {
  return (
    <Page>
      <h1>{t('contact.header')}</h1>
      <StyledList>
        <ContactMethod icon={<EmailIcon />}>
          <a
            href="mailto:alexanderandjoohee@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            alexanderandjoohee@gmail.com
          </a>
        </ContactMethod>
        <ContactMethod icon={<MobileIcon />} lang="en">
          <a
            href="tel:+1 (650) 382 9546"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1 (650) 382 9546
          </a>
        </ContactMethod>
        <ContactMethod icon={<MobileIcon />} lang="ko">
          <a
            href="tel:+1 (650) 695 3779"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1 (650) 695 3779
          </a>
        </ContactMethod>
        <ContactMethod icon={<WhatsappIcon />} lang="en">
          <a
            href="tel:+61 401 594 505"
            target="_blank"
            rel="noopener noreferrer"
          >
            +61 401 594 505
          </a>
        </ContactMethod>
        <ContactMethod icon={<FacebookIcon />} lang="en">
          <a
            href="https://www.facebook.com/alexander.szekely"
            target="_blank"
            rel="noopener noreferrer"
          >
            alexander.szekely
          </a>
        </ContactMethod>
        <ContactMethod icon={<KakaoIcon />} lang="ko">
          <a
            href="https://open.kakao.com/o/suqITReb"
            target="_blank"
            rel="noopener noreferrer"
          >
            1:1 Chat ({t('login.password')}: bali)
          </a>
        </ContactMethod>
      </StyledList>
    </Page>
  );
}

export default Contact;
