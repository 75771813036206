import { useState, useEffect, useMemo } from 'react';
import 'intersection-observer';

function useVisibility(ref, options = {}) {
  const [visibility, setVisibilty] = useState({});

  const observer = useMemo(() => {
    return new IntersectionObserver(entries => {
      setVisibilty(entries[0]);
    }, options);
  }, [options]);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [ref, observer]);

  return visibility;
}

export default useVisibility;
