import React from 'react';
import styled from 'styled-components';
import { HEADING, COLOUR_A, COLOUR_J, SMALL } from './styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const Container = styled.div`
  display: flex;
  padding: 20px;
  padding-top: 100px;
  justify-content: center;

  ${SMALL`
    padding-top: 80px;
  `}
`;

const Main = styled.main`
  max-width: 650px;

  h1 {
    ${HEADING}
    color: ${COLOUR_J};
    text-align: center;
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1;
  }

  h2 {
    ${HEADING}
    color: ${COLOUR_A};
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 15px;
    line-height: 1.5;
  }

  a {
    color: ${COLOUR_J};
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLOUR_A,
    },
    secondary: {
      main: COLOUR_J,
    },
  },
  typography: {
    fontFamily: `'Arimo', sans-serif`,
    useNextVariants: true,
  },
});

function Page({ children }) {
  return (
    <Container>
      <Main>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </Main>
    </Container>
  );
}

export default Page;
