import { useLayoutEffect, useState } from 'react';

const getIsScrolled = event =>
  window.scrollY > 0 || (event && event.target.scrollTop > 0);

function useScrolled() {
  const [isScrolled, setIsScrolled] = useState(getIsScrolled());

  useLayoutEffect(() => {
    const onScroll = event => setIsScrolled(getIsScrolled(event));
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, []);

  return isScrolled;
}

export default useScrolled;
