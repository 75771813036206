import React from 'react';
import ReactDOM from 'react-dom';
import { language } from './locales/translate';
import './index.css';
import App from './App';

const LocaleApp = () => (
  <div lang={language}>
    <App />
  </div>
);

ReactDOM.render(<LocaleApp />, document.getElementById('root'));
