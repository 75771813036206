import get from 'lodash/get';
import en from './en';
import ko from './ko';
import { getParam } from '../utils';

const languageFromBrowser = window.navigator.language.split('-')[0];
const languageFromDomain =
  window.location.host.indexOf('kr') >= 0 ? 'ko' : null;
const languageFromUrl = getParam('lang');

const language = languageFromUrl || languageFromDomain || languageFromBrowser;
const locale = language === 'ko' ? ko : en;

const t = path => get(locale, path, get(en, path));

export { language, locale };

export default t;
