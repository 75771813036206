import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_J } from '../../../styles';

const paths = [
  {
    offset: 286,
    d:
      'M519.52 1454.03c-70.88 2.87-143.07-30.44-183.01-84.44-11.19-15.13-18.78-37.93-3.4-49.07 5.72-4.14 13.33-5.4 20.65-6.32',
  },
  {
    offset: 501,
    d:
      'M473.69 1318.89c41.25 8.28 81.01 23.31 116.46 44.53-23.67 18.18-60.4 5.98-87.53-8.79-84.47-45.98-156.03-111.61-206.17-188.46',
  },
  {
    offset: 1078,
    d:
      'M452.05 1086.48c12.21 6.6 23.91 14.1 35 22.44 36.5 27.48 67.06 61.48 89.33 99.38-54.67 6.45-111.04-8.08-159.58-33.02s-90.1-59.79-128.77-96.35c-105.8-100.06-193.6-215.69-258.7-340.71-10.1-19.4-19.87-41-13.78-61.42 7.38-24.74 34.36-37.56 63.42-42.32',
  },
  {
    offset: 836,
    d:
      'M468.88 808.03c26.88 25.15 50.72 55.91 42.97 88.57-2.23 9.4-8.12 19.01-18.25 21.78-6.42 1.76-13.39.47-19.95-1.08-47.47-11.19-90.25-35.3-129.92-61.83C235.1 782.84 143.72 689.5 73.52 585.11 45.1 542.83 19.93 497.47 2.05 450.58',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="601.55"
      height="1646.29"
      color={COLOUR_J}
      paths={paths}
    />
  );
}

export default Line;
