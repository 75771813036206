import React, { useMemo } from 'react';
import { Spring, animated } from 'react-spring';
import clamp from 'lodash/clamp';
import { LINE_WIDTH } from '../../styles';

const Path = props => (
  <animated.path
    fill="none"
    strokeWidth={LINE_WIDTH}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
    {...props}
  />
);

function Line({ className, paths, color, width, height, isActive, config }) {
  const offsetPaths = useMemo(
    () =>
      paths.reduce((acc, path, i) => {
        const prev = acc[i - 1];
        return acc.concat({
          ...path,
          start: prev ? prev.start + prev.offset : 0,
        });
      }, []),
    [paths],
  );

  const totalLength = useMemo(() => {
    const last = offsetPaths[offsetPaths.length - 1];
    return last.start + last.offset;
  }, [offsetPaths]);

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <Spring
        from={{ offset: 0 }}
        to={{ offset: isActive ? totalLength : 0 }}
        config={config}
      >
        {({ offset }) =>
          offsetPaths.map(path => (
            <Path
              key={path.offset}
              d={path.d}
              stroke={color}
              strokeDasharray={path.offset}
              strokeDashoffset={clamp(
                path.offset - (offset - path.start),
                0,
                path.offset,
              )}
            />
          ))
        }
      </Spring>
    </svg>
  );
}

export default Line;
