import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_J } from '../../../styles';

const paths = [
  {
    offset: 595,
    d:
      'M422.07,1217.72c-72.67-4.38-144.88-16.36-215.07-35.67c-60.78-16.72-122.07-40.19-166.65-84.75c-5.89-5.89-11.71-12.63-12.96-20.87c-1.63-10.75,4.89-20.96,11.83-29.33c27.71-33.41,66.16-55.48,108.11-67.43',
  },
  {
    offset: 568,
    d:
      'M411.49,1010.44c11.44,4.44,22.81,9.06,34.09,13.76c6.56,2.73,13.59,5.91,16.99,12.15c7.78,14.29-8.73,29.63-23.64,36.13c-75.57,32.95-164.97,17.82-238.66-19.14c-34.77-17.44-68.95-41.54-83.91-77.45c-8.63-20.71,17.83-50.21,49.84-78.99',
  },
  {
    offset: 287,
    d: 'M309.91,740.37c61.96-88.32,75.14-133.61,91.78-267.58',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="501"
      height="1284.82"
      color={COLOUR_J}
      paths={paths}
    />
  );
}

export default Line;
