import React, { useRef } from 'react';
import styled from 'styled-components';
import { Spring, animated } from 'react-spring';
import t, { language } from '../../../locales/translate';
import useVisibility from '../../../hooks/useVisibility';
import image from './aj.jpg';
import bubble1_EN from './bubble1.en.svg';
import bubble2_EN from './bubble2.en.svg';
import bubble1_KO from './bubble1.ko.svg';
import bubble2_KO from './bubble2.ko.svg';
import Line from './line';

const bubble1 = language === 'ko' ? bubble1_KO : bubble1_EN;
const bubble2 = language === 'ko' ? bubble2_KO : bubble2_EN;

const Container = styled.div`
  width: ${props => props.sceneWidth}px;
  max-width: 65%;
  position: relative;
`;

const Bubble1 = styled(animated.img)`
  position: absolute;
  top: -20%;
  left: -30%;
  width: 40%;
  z-index: 2;
  transform-origin: bottom right;
`;

const Bubble2 = styled(animated.img)`
  position: absolute;
  top: -25%;
  right: -40%;
  width: 50%;
  z-index: 2;
  transform-origin: bottom left;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
`;

const StyledLine = styled(Line)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const intersectionOptions = { threshold: [0.5, 1] };

function Scene8({ width }) {
  const ref = useRef(null);
  const { intersectionRatio, isIntersecting } = useVisibility(
    ref,
    intersectionOptions,
  );

  return (
    <Container sceneWidth={width}>
      <Image src={image} alt="" ref={ref} />
      <Spring from={{ scale: 0 }} to={{ scale: isIntersecting ? 1 : 0 }}>
        {({ scale }) => (
          <Bubble1
            src={bubble1}
            alt={t('home.when')}
            style={{ transform: `scale(${scale})` }}
          />
        )}
      </Spring>
      <Spring
        from={{ scale: 0 }}
        to={{ scale: isIntersecting ? 1 : 0 }}
        config={{ delay: 500 }}
      >
        {({ scale }) => (
          <Bubble2
            src={bubble2}
            alt={t('home.date')}
            style={{ transform: `scale(${scale})` }}
          />
        )}
      </Spring>
      <StyledLine isActive={intersectionRatio > 0.8} />
    </Container>
  );
}

export default Scene8;
