import React, { useEffect, useLayoutEffect, useRef } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import Header from "./Header/Header";
import Story from "./Story/Story";
import Details from "./Details/Details";
import Contact from "./Contact/Contact";
import Login from "./Login";
import useLogin from "./hooks/useLogin";

const ScrollToTop = withRouter(({ location, children }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return children;
});

const TrackPageview = withRouter(({ location, children }) => {
  const updated = useRef(false);
  useEffect(() => {
    if (updated.current) {
      window.ga && window.ga("send", "pageview", location.pathname);
    }
    updated.current = true;
  }, [location]);
  return children;
});

function App() {
  const [isLoggedIn, loginProps] = useLogin();

  if (!isLoggedIn) {
    return <Login {...loginProps} />;
  }

  return (
    <Router>
      <TrackPageview>
        <ScrollToTop>
          <Header />
          <Route exact path="/" component={Details} />
          <Route path="/story" component={Story} />
          <Route path="/contact" component={Contact} />
        </ScrollToTop>
      </TrackPageview>
    </Router>
  );
}

export default App;
