import React from 'react';

export default {
  navigation: {
    rsvp: '회답',
    story: '스토리',
    contact: '연락',
  },
  login: {
    password: '비밀번호',
  },
  home: {
    married: `저희 결혼합니다`,
    omg: '대박!',
    wait: `잠깐만... 우리 벌써 결혼하지 않았어?`,
    but: `맞아, 근데...`,
    properly: `이번에는 제!대!로! 결혼하는거야!`,
    where: '어디에서?',
    here: '바로 여기!',
    location: {
      name: '노쿠 비치 하우스',
      place: '세미냑 - 발리',
      cta: '여기를 눌러 위치를 확인하세요',
    },
    when: '언제?',
    date: '2019년 8월 31일',
    join: `해질녘무렵의 결혼식과 리셉션을 함께해주세요!`,
    click: '여기를 눌러',
    rsvp: '초대장에 회답해주세요',
    day: '정확한 웨딩디테일은 추후에 업데이트 됩니다 :).',
    see: '발리에서 봬요 (하트)',
  },
  rsvp: {
    header: '회답하기',
    intro:
      '작은 결혼식이기때문에 초대되신 분들만 아래 형식에 맞추어 답해주세요. 가능한 빨리 답해주실수록 준비에 힘이됩니다.',
    you: '본인',
    partner: '파트너',
    name: '이름',
    email: '이메일',
    attending: '참석여부',
    yes: '네, 참석하겠습니다',
    no: `아니요, 아쉽지만 참석하지 못할 것 같습니다.`,
    diet: '특별한 식단이 필요하신가요?',
    none: '아니요',
    gf: '글루텐프리',
    vegetarian: '채식주의자',
    vegan: '비건',
    message: '궁금한 점이 있으신가요?',
    submit: '제출',
    sorry: `초대장에 회답해주셔서 감사합니다. 아쉽지만 다음번에 뵙겠습니다 :)`,
    thanks: (
      <>
        <p>초대장에 회답해주셔서 감사합니다. 8/31 발리에서 뵙겠습니다! :)</p>
        <p>이벤트 디테일은 다음 이메일을 기다려주새요.</p>
      </>
    ),
    error: '제출에 문제가 생겼습니다. 다시 제출해주세요',
  },
  contact: {
    header: '연락',
  },
};
