import { useCallback, useEffect, useState, useRef } from 'react';

function useOrientation() {
  const mql = useRef(window.matchMedia('(orientation: landscape)'));
  const [isLandscape, setIsLandscape] = useState(mql.current.matches);

  const onChange = useCallback(event => setIsLandscape(event.matches), []);

  useEffect(() => {
    const listener = mql.current;
    listener.addListener(onChange);
    return () => listener.removeListener(onChange);
  }, [onChange]);

  return [isLandscape, !isLandscape];
}

export default useOrientation;
