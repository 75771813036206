import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_A } from '../../../styles';

const paths = [
  {
    offset: 25,
    d: 'M297.2,1047.47c8.28-0.22,16.58,0.24,24.81,1.41',
  },
  {
    offset: 14,
    d: 'M230.31,1064.23c4.02-1.98,8.12-3.8,12.28-5.45',
  },
  {
    offset: 20,
    d: 'M170.61,1115.28c3.76-5.46,7.88-10.65,12.32-15.54',
  },
  {
    offset: 315,
    d:
      'M306.21,911.13c8.55,4.03,16.18,9.76,22.36,17.47c30.6,38.15,4.6,96.35-32.05,128.73c-35.12,31.03-79.5,51.46-125.91,57.96',
  },
  {
    offset: 1137,
    d:
      'M642.37,455.34c11.39-1.14,22.8-2.12,34.22-2.94c30.25-2.18,63.76-2.22,86.85,17.44c34.58,29.46,26.4,85.15,7.52,126.48c-46.43,101.65-135.88,176.95-228.85,238.96c-129.96,86.69-276.28,155.59-431.99,168.26c12.38-20.54,28.26-38.93,46.65-54.32',
  },
  {
    offset: 936,
    d:
      'M775.19,265.03c11.9,30.75-15.05,61.86-40.14,83.25C601.27,462.35,451.02,555.42,298.78,643.35c-74.27,42.9-151.1,85.39-235.69,99.59c-0.57-17.52,3.49-34.71,10.75-50.67',
  },
  {
    offset: 38,
    d: 'M76.48,355.16c11.03-5.6,22.15-11.02,33.35-16.26',
  },
].reverse();

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="801.5"
      height="1266.06"
      color={COLOUR_A}
      paths={paths}
    />
  );
}

export default Line;
