import React, { useRef } from 'react';
import styled from 'styled-components';
import { Spring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import t, { language } from '../../../locales/translate';
import useVisibility from '../../../hooks/useVisibility';
import image from './aj.jpg';
import bubble1_EN from './bubble1.en.svg';
import bubble2_EN from './bubble2.en.svg';
import bubble1_KO from './bubble1.ko.svg';
import bubble2_KO from './bubble2.ko.svg';

const bubble1 = language === 'ko' ? bubble1_KO : bubble1_EN;
const bubble2 = language === 'ko' ? bubble2_KO : bubble2_EN;

const Container = styled.div`
  width: ${props => props.sceneWidth}px;
  max-width: 80%;
  position: relative;
`;

const Bubble = styled(animated.img)`
  width: 100%;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
`;

const BaseLink = styled(Link)`
  position: absolute;
  width: 45%;
  z-index: 2;
  text-decoration: none;
  transition: transform 0.3s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledLink1 = styled(BaseLink)`
  top: -5%;
  left: -5%;
`;

const StyledLink2 = styled(BaseLink)`
  top: 0%;
  right: 0%;
`;

const intersectionOptions = { threshold: 0.5 };

function Scene10({ width }) {
  const ref = useRef(null);
  const { isIntersecting } = useVisibility(ref, intersectionOptions);

  return (
    <Container sceneWidth={width}>
      <Image src={image} alt="" ref={ref} />

      <Spring from={{ scale: 0 }} to={{ scale: isIntersecting ? 1 : 0 }}>
        {({ scale }) => (
          <StyledLink1 to="/rsvp">
            <Bubble
              src={bubble1}
              alt={t('home.click')}
              style={{ transform: `scale(${scale})` }}
            />
          </StyledLink1>
        )}
      </Spring>
      <Spring
        from={{ scale: 0 }}
        to={{ scale: isIntersecting ? 1 : 0 }}
        config={{ delay: 300 }}
      >
        {({ scale }) => (
          <StyledLink2 to="/rsvp">
            <Bubble
              src={bubble2}
              alt={t('home.rsvp')}
              style={{ transform: `scale(${scale})` }}
            />
          </StyledLink2>
        )}
      </Spring>
    </Container>
  );
}

export default Scene10;
