import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_A } from '../../../styles';

const paths = [
  {
    offset: 74,
    d: 'M19.75,131.25c0,0,65,20,70.25,22.75',
  },
  {
    offset: 430,
    d:
      'M320.67,558.55c21.83,65.29,144.38,193.18,219.27,157.25c48.56-23.29-27.6-113.35-30.52-118.45',
  },
  {
    offset: 424,
    d:
      'M479.95,759.32c6.29,14.81,28.94,98.18,8.4,109.01c-41.82,22.05-193.13-194.03-204.99-221.18',
  },
  {
    offset: 536,
    d:
      'M79.37,513.7c0,0-85.86,5.39-77.11,44.84s62.81,93.99,101.28,126.97s92.62,60.35,115.78,69.41c23.19,9.07,98.73,22.84,78.51-2.01c-20.22-24.85-29.1-30.28-29.1-30.28',
  },
  {
    offset: 329,
    d:
      'M277.79,847.11C213,838,194,830,152.69,808.4c-27-14.12-90.69-58.9-87.4-86.91c2.77-23.62,13.25-26.95,51.48-35.97',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="601.51"
      height="979.71"
      color={COLOUR_A}
      paths={paths}
    />
  );
}

export default Line;
