import { useCallback } from 'react';
import useSimpleStorage from './useSimpleStorage';

const PASSWORD = 'bali';
const KEY = 'logged-in';
const VALUE = 'true';

function useLogin() {
  const [isLoggedIn, setLoggedIn] = useSimpleStorage(KEY);
  const onLogin = useCallback(() => setLoggedIn(VALUE), [setLoggedIn]);
  return [isLoggedIn === VALUE, { onLogin, password: PASSWORD }];
}

export default useLogin;
