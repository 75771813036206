import { css } from 'styled-components';

export const COLOUR_J = '#FA6E59';
export const COLOUR_A = '#2988BC';

export const LINE_WIDTH = 6;

const LANG = lang => (...args) => css`
  [lang='${lang}'] & {
    ${css(...args)}
  }
`;

const MEDIA = query => (...args) => css`
  @media (${query}) {
    ${css(...args)}
  }
`;

export const ENGLISH = LANG('en');
export const KOREAN = LANG('ko');

export const KOREAN_HEADING = KOREAN`
  font-family: 'Black Han Sans', sans-serif;
  font-weight: normal;
`;

export const ENGLISH_HEADING = ENGLISH`
  font-family: 'Pacifico', sans-serif;
  font-weight: normal;
`;

export const HEADING = css`${ENGLISH_HEADING}${KOREAN_HEADING}`;

export const PORTRAIT = MEDIA('orientation: portrait');
export const SMALL = MEDIA('max-width: 768px');
