import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_A } from '../../../styles';

const paths = [
  {
    offset: 80,
    d:
      'M189.94,829.46c-11.29-4.1-22.76-8.46-32.07-16.05c-9.31-7.59-16.25-19.09-15.35-31.07c0.2-2.63,0.76-5.18,1.64-7.64',
  },
  {
    offset: 354,
    d:
      'M223.85,742.46c19.17,0.58,38.24,4.46,56.06,11.53c2.19,0.87,4.46,1.86,5.89,3.73c4.76,6.26-3.68,14.35-11.28,16.35c-16.82,4.43-34.77,0.47-50.98-5.85c-19.5-7.6-37.67-18.61-53.43-32.39c-12.48-10.91-23.62-23.83-29.9-39.17c-6.27-15.34-7.21-33.37,0.07-48.26c4.78-9.77,12.73-17.64,21.21-24.46c5.26-4.23,8.93-8.11,14.68-11.64',
  },
  {
    offset: 533,
    d:
      'M287.18,589.43c9.77,1.58,19.32,4.63,27.02,10.71c1.99,1.57,3.97,3.59,4.11,6.13c0.2,3.44-2.99,6.05-5.95,7.82c-25.7,15.39-60.2,14.63-85.19-1.88c-13.72-9.07-24.33-22.16-33.14-36.06c-27.89-44.01-39.16-98.76-28.57-149.77c5.04-24.28,39.67-95.74,96.9-162.5',
  },
  {
    offset: 74,
    d: 'M443.01,140.43c23.14-7.16,47.45-11.21,72.81-11.21',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="800"
      height="1003.64"
      color={COLOUR_A}
      paths={paths}
    />
  );
}

export default Line;
