import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_J } from '../../../styles';

const paths = [
  {
    offset: 522,
    d:
      'M653.39,942.76c26.72,2.78,53.07,12.46,62.7,36.31c8.04,19.89,0.73,43.03-11.52,60.64c-20.23,29.08-52.29,47.5-84.34,62.6c-82.79,38.99-172.04,61.94-260.68,84.68',
  },
  {
    offset: 37,
    d: 'M516.19,958.91c11.82-3.31,23.78-6.16,35.83-8.53',
  },
  {
    offset: 760,
    d:
      'M658.6,771.04c16.46,4.61,30.04,11.81,42.7,23.16c16.11,14.44,26.47,36.5,22.88,57.83c-3.97,23.6-23.17,41.29-41.59,56.58c-108.74,90.3-234.94,167.02-375.1,185.2c11.89-22.8,31.34-40.73,51.7-56.43c19.34-14.91,39.89-28.22,61.36-39.82',
  },
  {
    offset: 857,
    d:
      'M636.31,395.18c25.65-0.14,51.29,1.43,76.77,5c14.43,2.02,29.94,5.3,39.53,16.27c13.06,14.96,9.91,37.94,3.2,56.63c-16.66,46.41-49.7,84.92-83.58,120.74c-49.24,52.06-101.77,100.95-156.08,147.7c-62.52,53.82-131.12,106.57-212.33,121.1c12.69-14.3,34.95-31.95,50.7-42.22',
  },
  {
    offset: 852,
    d:
      'M678.91,293.5c-4.03,5.68-8.59,11.1-13.61,16.19c-39.97,40.55-98.62,75.95-144.04,110.28C364.31,538.62,194.83,651.25,1.67,688.62c13.76-13.93,27.93-27.45,42.47-40.56',
  },
  {
    offset: 59,
    d: 'M68.82,343.6c15.28-12.16,31.44-22.97,48.26-32.68',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="801.5"
      height="1266.06"
      color={COLOUR_J}
      paths={paths}
    />
  );
}

export default Line;
