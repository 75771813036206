import React, { useRef } from 'react';
import styled from 'styled-components';
import { Spring, animated } from 'react-spring';
import t, { language } from '../../../locales/translate';
import useVisibility from '../../../hooks/useVisibility';
import image from './aj.jpg';
import bubble_EN from './bubble.en.svg';
import bubble_KO from './bubble.ko.svg';

const bubble = language === 'ko' ? bubble_KO : bubble_EN;

const Container = styled.div`
  width: ${props => props.sceneWidth}px;
  max-width: 70%;
  position: relative;
`;

const Bubble = styled(animated.img)`
  position: absolute;
  bottom: 80%;
  left: 70%;
  width: 70%;
  z-index: 2;
  transform-origin: bottom left;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  z-index: 0;
`;

const intersectionOptions = { threshold: 0.5 };

function Scene1({ width }) {
  const ref = useRef(null);
  const { isIntersecting } = useVisibility(ref, intersectionOptions);

  return (
    <Container sceneWidth={width}>
      <Image ref={ref} src={image} alt="" />
      <Spring from={{ scale: 0 }} to={{ scale: isIntersecting ? 1 : 0 }}>
        {({ scale }) => (
          <Bubble
            src={bubble}
            alt={t('home.married')}
            style={{
              opacity: scale,
              transform: `rotate(${(1 - scale) * 45}deg) scale(${scale})`,
            }}
          />
        )}
      </Spring>
    </Container>
  );
}

export default Scene1;
