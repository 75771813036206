import React from 'react';
import AnimatedLine from '../AnimatedLine';
import { COLOUR_J } from '../../../styles';

const paths = [
  {
    offset: 110,
    d:
      'M322.77,978.33c-28.17,5.06-58.65-5.69-77.41-27.31c-5.13-5.91-9.61-13.24-8.92-21.04',
  },
  {
    offset: 242,
    d:
      'M314.89,900.4c10.72,3.3,20.64,8.99,28.6,16.98c-37.3,14.6-79.94-7.91-105.46-38.79c-15.83-19.16-27.83-45.66-17.91-68.44c1.85-4.24,4.34-8.05,7.31-11.48',
  },
  {
    offset: 177,
    d:
      'M314.74,777.42c5.26,2.14,10.12,5.14,14.35,9.17c2.66,2.53,5.1,5.6,5.61,9.24c1.3,9.27-9.77,15.23-19.06,16.45c-22.76,2.99-50.38-1.73-66.22-18.35c-9.54-10-27.12-27.03-24.85-40.67',
  },
  {
    offset: 476,
    d:
      'M336,645.36c82.8-48.74,176.19-66.83,271.59-94.87c25.62-7.53,51.46-15.32,74.68-28.51c31.55-17.92,57.03-43.71,72.72-76.4',
  },
  {
    offset: 1306,
    d:
      'M772.28,371.92c1.06-47.91-17.74-96.19-51.55-130.26c-4.19-4.22-8.99-8.43-14.89-9.23c-9.57-1.29-17.69,6.69-23.49,14.41c-13.09,17.41-23.11,37.11-29.47,57.93c-3.2,10.46-1.33,26.66,9.6,26.1c-3.99-23.09-16.19-44.67-33.92-60c-7.34-6.34-18.3-11.69-26.23-6.11c-3.83,2.7-5.78,7.29-7.32,11.71c-12.19,34.86-10.35,74.42,5.05,107.99c12.55,27.37,33.28,50.1,54.92,71.04c37.64,36.42,78.97,68.84,121.58,99.29c23.31,16.66,47.09,32.78,72.35,46.3c25.82,13.81,53.05,24.81,79.21,37.98c82.31,41.43,153.53,104.66,204.43,181.48c7.73,11.66,14.69,28.19,5.14,38.41c-5.15,5.51-13.31,6.73-20.82,7.28c-10.59,0.77-21.22,0.82-31.81,0.16',
  },
  {
    offset: 465,
    d:
      'M970.52,831.77c-20.23-12.4-35.01-29.21-41.71-51.98c-1.32-4.5-2.12-9.34-0.88-13.86c4.45-16.2,27.83-15.32,43.54-9.37c67.69,25.67,123.88,80.2,151.57,147.09c8.01,19.36,10.57,47.34-8.55,55.9c-6.13,2.75-13.13,2.56-19.84,2.14c-10.88-0.67-21.74-1.79-32.53-3.37',
  },
  {
    offset: 22,
    d: 'M983.93,938.61c-6.59-2.4-13.11-4.98-19.56-7.73',
  },
];

function Line(props) {
  return (
    <AnimatedLine
      {...props}
      width="1200"
      height="1147"
      color={COLOUR_J}
      paths={paths}
      config={{ duration: 1500 }}
    />
  );
}

export default Line;
